import { computed, ref } from "vue";
import { defineStore } from 'pinia';

export const useNoticesStore = defineStore('notices', () => {

  let noticeId = 0;

  const notices = ref<Array<{ message: string; type: string; id: number; } | null>>([])
  const getNotices = computed(() => notices.value);

  const setNotice = (notice: string, type: string, duration: number = 5000) => {

    let noticeObj = {
      message: notice,
      type: type,
      id: noticeId,
      duration: duration
    };

    notices.value.push(noticeObj);

    noticeId++;

    // Automatically remove the notice after the specified duration
    if (duration > 0) {
      setTimeout(() => {
        removeNotice(noticeObj.id);
      }, duration);
    }
  };

  const removeNotice = (id: number) => {
    const noticeIndex = notices.value.findIndex((n) => {
      return n && n.id == id;
    });

    if (noticeIndex !== -1) {
      notices.value.splice(noticeIndex, 1);
    }
  }

  const clearNotices = () => {
    notices.value.splice(0, notices.value.length);
  };

  return {
    notices,
    setNotice,
    getNotices,
    removeNotice,
    clearNotices
  };
});
